var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-card',{attrs:{"titleCard":_vm.$t('sidebar.completedProjects')}},[_c('div',{staticClass:"table-dashboard temporary-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersUserManagement,"items":_vm.indexProjectsList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"name"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"dark":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" مشاهده پروژه نهایی ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0 my-1 service-table-title"},[_vm._v(_vm._s(item.title))])])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("readMore")(item.description,15, '...'))+" ")])])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("thousandMask")(item.price))+" ")])])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("changeDate")(item['created_at']))+" ")])])])]}}])}),(_vm.indexProjectsList.length >= 1)?_c('div',{staticClass:"col-12 text-center pt-2"},[_c('v-pagination',{attrs:{"color":"primary","length":_vm.paginationData['last_page'],"total-visible":_vm.paginationData.total},on:{"input":function($event){return _vm.changePage($event)}},model:{value:(_vm.paginationData['current_page']),callback:function ($$v) {_vm.$set(_vm.paginationData, 'current_page', $$v)},expression:"paginationData['current_page']"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }